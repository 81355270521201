var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-task-handler","sidebar-class":"sidebar-lg","visible":_vm.isTaskHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-task-handler-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.taskLocal.id)?_c('b-button',{attrs:{"size":"sm","variant":_vm.taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'},on:{"click":function($event){_vm.taskLocal.isCompleted = !_vm.taskLocal.isCompleted}}},[_vm._v(" "+_vm._s(_vm.taskLocal.isCompleted ? 'Completed' : 'Mark Complete')+" ")]):_c('h5',{staticClass:"mb-0"},[_vm._v(" Add Keywords ")]),_c('div',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskLocal.id),expression:"taskLocal.id"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-task'); hide();}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",class:{ 'text-warning': _vm.taskLocal.isImportant },attrs:{"icon":"StarIcon","size":"16"},on:{"click":function($event){_vm.taskLocal.isImportant = !_vm.taskLocal.isImportant}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-0",attrs:{"show":_vm.dismissCountDown,"dismissible":"","fade":"","variant":"danger"},on:{"dismiss-count-down":_vm.countDownChanged}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.errorMessage))])])]),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Keywords","label-for":"task-title"}},[_c('b-form-input',{attrs:{"id":"task-title","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Keyword Name","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Message","label-for":"task-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"quil-content","options":_vm.editorOption,"required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1),_c('b-form-group',{attrs:{"label":"Search Words","label-for":"custom-fields"}},[_c('b-form-tags',{attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list","label":"Custom Fields"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"Keywords - Press enter to add"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v(" Add ")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-0",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" remove ")])],1)}),1)]}}],null,true),model:{value:(_vm.customFields),callback:function ($$v) {_vm.customFields=$$v},expression:"customFields"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"id":"contactCreate","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.contactCreationForm.apply(null, arguments)}}},[_vm._v(" Create ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }